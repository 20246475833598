.html{
  font-size: 14px;
  height: 100%;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 10px;
  color: #8D948D;
  background-color: floralwhite;
}

.header {
  font-size: 2.5em;
  color: #8D948D;
}
.header img {
  width: 100px;
}

h1 {
  color: #8D948D;
}
